/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.co-orange {
  color: rgb(166, 145, 111);
}
.co-white {
  color: white;
}

.orangeTextClash {
  font-size: 28px;
}
.orangeTextClash {
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
  /* color: rgb(166, 145, 111); */
  color: #4a320b;
  font-family: var(--font_family_clash_display);
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.textAccordin, .textStake {
  font-size: 20px;
  letter-spacing: -.4px;
}
.textStake {
  font-weight: 400;
  text-transform: capitalize;
  line-height: 30px;
}


.backgroundOrangeLinear {
  background: linear-gradient(231deg, #a18c68 10.79%, #a18c68 132.55%);
}
.rounded-\[30px\] {
  border-radius: 30px;
}
.overflow-x-auto {
  overflow-x: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (min-width: 1024px){
.poppineText {
  font-size: 28px;
}
}

@media (min-width: 768px)
{

.poppineText {
  font-size: 22px;
}
}
.poppineText {
  font-weight: 400;
  line-height: 151%;
  letter-spacing: -.56px;
  font-size: 18px;
}

.grid {
  display: grid;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.mt-2 {
  margin-top: .5rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.borderAccordin {
  border-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(158 176 203 / var(--tw-border-opacity));
}

.textAccordin {
  font-weight: 600;
  line-height: 28.65px;
}
.textAccordin, .textStake {
  font-size: 20px;
  letter-spacing: -.4px;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
@media (min-width: 1024px){
.poppineText {
  font-size: 28px;
}
}
@media (min-width: 768px){
.poppineText {
  font-size: 22px;
}
}
.poppineText {
  font-weight: 400;
  line-height: 151%;
  letter-spacing: -.56px;
  font-size: 18px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pr-\[32px\] {
  padding-right: 32px;
}
.pl-\[32px\] {
  padding-left: 32px;
}
.pb-\[5px\] {
  padding-bottom: 5px;
}
.cardBuyPika {
  width: 100%;
  height: auto;
  border-radius: 30px;
  background: #fff;
  box-shadow: 10px 10px 50px 0 rgba(0,0,0,.1);
}

.hover\:bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.btn:hover {
  background: linear-gradient(to right #ff6400, #a18c68);
}
.p-2 {
  padding: .5rem;
}
.to-\[\#F9A52E\] {
  --tw-gradient-to: #a18c68 var(--tw-gradient-to-position);
}
.from-\[\#FF6400\] {
  --tw-gradient-from: #a18c68  var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(255, 100, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.btnConnectWallet {
  color: white;
  text-align: center;
  text-shadow: 0 5px 50px rgba(0,0,0,.12);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -.48px;
  border-radius: 10px;
  background: black;
}
[role=button], button {
  cursor: pointer;
}

.btnConnectWallet:hover {
  color: white;
  background: #a18c68 ;
}
.bg-custom-color {
  background-color: #c0ccdd;
}

.space-y-4>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem* calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem* var(--tw-space-y-reverse));
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.btn, .btnSm {
  color: white;
  text-align: center;
  text-shadow: 0 5px 50px rgba(0,0,0,.12);
  letter-spacing: -.48px;
  border-radius: 10px;
  background: #a18c68;
  font-size: 24px;
  font-weight: 500;
}
.bg-white {
  background-color: white;
}
